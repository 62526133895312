import React from "react";
import {Link} from "gatsby";

import Layout from "../../components/layout";
import {GettingStartedSidebar as Sidebar} from "../../components/sidebar";
import {GettingStartedBreadcrumb as Breadcrumb} from "../../components/breadcrumb";
import {AskHowToInfoAlert} from "../../components/alert";
import {CodeSnippet} from "../../components/snippet";
import {KeyHeader} from "../../components/typography";

const pageTitle = "Handle Long/CLob/BLob";

const Page = () => (
    <Layout pageTitle={pageTitle}
            sidebarNav={<Sidebar/>}
            mobileNav={<Breadcrumb activeName={pageTitle}/>}
    >
        <p>
            SQLAPI++ supports four types for working with this kind of
            data (see {' '}<a href="#server_specific_notes">Server specific notes</a>{' '}
            later on this page for details about how SQLAPI++ maps this types on a
            different SQL platforms):
        </p>

        <table className="table table-sm">
            <thead className="thead-light">
            <tr>
                <th>Type</th>
                <th>Description</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>
                    <div>LongBinary</div>
                    <code className="text-nowrap">SA_dtLongBinary</code>
                </td>
                <td>
                    Generally this type is mapped to an appropriate SQL
                    type that holds long binary data of variable length but does NOT
                    supports "handle" semantics
                </td>
            </tr>
            <tr>
                <td>
                    <div>LongChar</div>
                    <code className="text-nowrap">SA_dtLongChar</code>
                </td>
                <td>
                    Generally this type is mapped to an appropriate SQL
                    type that holds long character data of variable length but does NOT
                    supports "handle" semantics
                </td>
            </tr>
            <tr>
                <td>
                    <div>BLob (Binary Large object)</div>
                    <code className="text-nowrap">SA_dtBLob</code>
                </td>
                <td>
                    Generally this type is mapped to an appropriate SQL
                    type that holds large binary data of variable length and does supports
                    "handle" semantics
                </td>
            </tr>
            <tr>
                <td>
                    <div>CLob (Character Large object)</div>
                    <code className="text-nowrap">SA_dtCLob</code>
                </td>
                <td>
                    Generally this type is mapped to an appropriate SQL
                    type that holds large character data of variable length and does
                    supports "handle" semantics
                </td>
            </tr>
            </tbody>
        </table>

        <p>Working with Long or Lob data includes the following:</p>
        <ul>
            <li>
                {' '}<a href="#binding_data">Binding Long or Lob data</a>{' '}
                into input variables (SQL statements) or input parameters
                (stored procedures/functions)
            </li>
            <li>
                {' '}<a href="#reading_data">Reading Long or Lob data</a>{' '}
                (from fields in result set or from output parameters of stored procedure)
            </li>
        </ul>

        <KeyHeader id="binding_data">Binding Long or Lob Data</KeyHeader>
        <p>
            Let say we want to update BLob field named FBLOB from table
            named TEST where some other field named FKEY is equal to 'KEY':
        </p>
        <CodeSnippet language="sql">UPDATE TEST SET FBLOB = :fblob WHERE FKEY = 'KEY'</CodeSnippet>

        <p>
            Field should be updated with the content of a file named 'blob.bin'.
        </p>

        <p>
            First, initialize the command object:
        </p>
        <CodeSnippet>{`
SACommand cmd(
    &con, 
    _TSA("UPDATE TEST SET FBLOB = :fblob WHERE FKEY = 'KEY'"));
            `.trim()}
        </CodeSnippet>

        <p>For more information see {' '}<Link to="/HowTo/commands/">Execute SQL Statement</Link>.</p>
        <p>
            Next step is to actually bind the content of a file into input variable.
            The following code binds parameter {' '}<code>:fblob</code>{' '} with value of file content read:
        </p>
        <CodeSnippet>{`
SAString sContent = SomeFunctionThatReadFileContent("blob.bin");
cmd.Param(_TSA("fblob")).setAsBLob() = sContent;
            `.trim()}
        </CodeSnippet>

        <p>
            All that we need now is to execute a query:
        </p>
        <CodeSnippet>cmd.Execute();</CodeSnippet>

        <p>
            For using piecewise capabilities of SQLAPI++ for binding Long
            or Lob types see {' '}<a href="/Examples/step5.cpp">example</a>.
        </p>

        <KeyHeader id="reading_data">Reading Long or Lob data</KeyHeader>
        <p>
            Let's say we want to retrieve BLob field named {' '}<code>FBLOB</code>{' '} from table
            named {''}<code>TEST</code>{' '} where some other field named
            {' '}<code>FKEY</code>{' '} (primary key) is equal to {' '}<code>'KEY'</code>:
        </p>
        <CodeSnippet language="sql">
            SELECT FBLOB FROM TEST WHERE FKEY = 'KEY'
        </CodeSnippet>

        <p>
            Field should be read into a file named 'blob.bin'.
        </p>

        <p>
            First, create command object:
        </p>
        <CodeSnippet>{`
SACommand cmd(
    &con,
    _TSA("SELECT FBLOB FROM TEST WHERE FKEY = 'KEY'"));
            `.trim()}
        </CodeSnippet>

        <p>
            For more information see {' '}<Link to="/HowTo/commands/">Execute SQL Statement</Link>.
        </p>

        <p>
            Then execute the query:
        </p>
        <CodeSnippet>cmd.Execute();</CodeSnippet>

        <p>
            Finally, fetch the row(s) and access BLob data:
        </p>
        <CodeSnippet>{`
while(cmd.FetchNext())
{
    SAString sBLob = cmd.Field(_TSA("FBLOB")).asBLob();
    SomeFunctionToSaveBLobToFile("blob.bin", sBLob);
}
                `.trim()}
        </CodeSnippet>

        <p>
            For using piecewise capabilities of SQLAPI++ for reading
            Long or Lob types see this {' '}<a href="/Examples/step6.cpp">example</a>.
        </p>


        <AskHowToInfoAlert/>
    </Layout>
);

export default Page;
